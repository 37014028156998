import log from 'loglevel';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import differenceInHours from 'date-fns/difference_in_hours';
import {
  THREATLIST_SET_VISIBLESITES,
  THREATLIST_APPLY_FILTER,
  THREATLIST_SET_PAGE,
  THREATLIST_SET_OFFSET_Y
} from "./ThreatListDuck";
import { getWeatherCode } from 'common/helpers/helpers'
import { percentToIn, INCHES10, INCHES2, INCHES6, MPH50 } from 'common/components/FilterSlider';
import { getAllEventDays, getPeriodDay } from 'common/helpers/datahelpers'
import { H24, H48, H72, INPROGRESS } from 'common/components/TimingSelector/TimingSelectorDuck'
import { FEDSTATE, ZIPCODE } from 'common/components/LocationSelector/LocationSelectorDuck'
import { usStateMap } from 'common/helpers/locationAbbrev'
import { COMPANY_SITES, ACTIVE_WINTER_SITES, ACTIVE_NONWINTER_SITES, UNIT_IMPERIAL } from "SiteFilter/SiteFilterDuck";
import { ACTIONS } from 'Map/MapActions';
import { filterBySearchBox } from "common/helpers/helpers";

const matchSiteLocation = (site, locObj) => {
  if (!locObj || Object.keys(locObj).length === 0) return true;
  switch (locObj.stateOrZipCode) {
    case FEDSTATE:
      let flag = true;
      if (locObj.fedState) flag &= usStateMap[site.state.trim()] === locObj.fedState;
      if (locObj.city) flag &= locObj.city === site.city.trim();
      return flag
    case ZIPCODE:
      return locObj.zipCode ? site.zipCode.trim() === locObj.zipCode : true;
    default:
      return true;
  }
}

const matchTiming = (timingVal, firstPeriodIdx, isOngoing) => {
  switch (timingVal) {
    case INPROGRESS: return isOngoing;
    case H24: return firstPeriodIdx === 0 && !isOngoing;
    case H48: return firstPeriodIdx === 1;
    case H72: return firstPeriodIdx === 2;
    default: return true;
  }
}

const firstEventPeriodIdx = eventsByType => getPeriodDay(getAllEventDays(eventsByType))

// returns whether there's a match at all (undefined filterVal is a match)
// and all events matched by cmpFun 
const findMatchingEventWithFilter = (filterVal, events, cmpFun) =>
  filterVal 
    ? events.filter(cmpFun)
    : events;

const filterByFilterParams = ({
  site, 
  threatfilter: {popSliderVal, snowSliderVal, iceSliderVal, rainSliderVal, windSliderVal, typeVal, locVal, minRainIn, minRainMm},
  winterType,
  timingSelector,
  unit = UNIT_IMPERIAL,
  siteWithoutEvents = false
}) => {
  if(siteWithoutEvents){
    if ( !(popSliderVal || snowSliderVal || iceSliderVal || rainSliderVal || windSliderVal || typeVal || locVal || minRainIn || minRainMm)){
      return true;
    } else {
      if (locVal && locVal.stateOrZipCode !== 'NONE'){
       return matchSiteLocation(site, locVal)
      } else {
        return false;
      }
    }

  } else {
    const eventsByType = site.events.filter(
      e => e.type === winterType || winterType === undefined
    );
    const firstPeriodIdx = firstEventPeriodIdx(eventsByType)
    if (firstPeriodIdx >= 3) return false; // only show up to 72h

    let matchingEvents = eventsByType;

    // const probabilityMatches = popSliderVal ? (popSliderVal <= eventsByType[0].maxPop) : true;

    matchingEvents = findMatchingEventWithFilter(popSliderVal, matchingEvents, (e => (popSliderVal || 0) <= e.maxPop));
    matchingEvents = findMatchingEventWithFilter(snowSliderVal, matchingEvents, (e => percentToIn(snowSliderVal, INCHES10) <= e.totalSnowIN));
    matchingEvents = findMatchingEventWithFilter(iceSliderVal, matchingEvents, (e => percentToIn(iceSliderVal, INCHES2) <= e.totalIceaccum));
    matchingEvents = findMatchingEventWithFilter(rainSliderVal, matchingEvents, (e => percentToIn(rainSliderVal, INCHES6) <= e.totalPrecipIN));
    matchingEvents = findMatchingEventWithFilter(windSliderVal, matchingEvents, (e => percentToIn(windSliderVal, MPH50) <= eventsByType[0]?.windSpeedMaxMPH));
    if(unit === UNIT_IMPERIAL) {
      matchingEvents = findMatchingEventWithFilter(minRainIn, matchingEvents, (e => minRainIn <= e.totalPrecipIN));
    } else {
      matchingEvents = findMatchingEventWithFilter(minRainMm, matchingEvents, (e => minRainMm <= e.totalPrecipMM));
    }

    // match against Threat Types for Winter weather
    matchingEvents = findMatchingEventWithFilter(typeVal, matchingEvents, ev =>
      ev.threatWeatherPrimaryCodes.some(cd => typeVal[getWeatherCode(cd)])
    );

    const locationMatches = matchSiteLocation(site, locVal)

    site.filteredEvents = matchingEvents;

    // const isOngoing = eventsByType && eventsByType.length > 0 && eventsByType.some(e => e.ongoing);
    const isOngoing = !!findMatchingEventWithFilter(1, matchingEvents, (e => e.ongoing)).length;
    const firstPeriodIdxOfMatchingEvents = firstEventPeriodIdx(matchingEvents)
    const timingMatches = matchTiming(timingSelector.timing, firstPeriodIdxOfMatchingEvents, isOngoing);

    // console.groupCollapsed(site.name +', '+ site.city);
    // console.log('timingVal',timingSelector.timing, 'firstPeriodIdx', firstPeriodIdxOfMatchingEvents, 'isOngoing', isOngoing);
    // log.debug(
    //   "filterByFilterParams",
    //   timingMatches,
    //   locationMatches,
    //   matchingEvents
    // );
    // console.groupEnd();

    return !!matchingEvents.length && timingMatches && locationMatches;
  }

}

const filterByMySitesFilter = (s, selectedFilter, mySites) => {
  return selectedFilter === COMPANY_SITES ||
    mySites.siteIds.includes(s.id)
}


const getWinterType = (winterFilter) =>
  (winterFilter === ACTIVE_WINTER_SITES && 'winter') || // return 'winter'
  (winterFilter === ACTIVE_NONWINTER_SITES && 'nonwinter') || // return 'nonwinter'
  undefined; // winter toggle unset

// returns true if Site has right winter/nonwinter type or winter toggle is unset
const filterByWinterTypeFilter = (s, winterFilter) => {
  const winterType = getWinterType(winterFilter);
  return winterType
    ? s.types.includes(winterType)
    : true;
};

// returns true if Site has events of right winter/nonwinter type or toggle is unset
const filterEventsByWinterTypeFilter = (s, winterFilter) => {
  const winterType = getWinterType(winterFilter);
  return winterType
    ? s.events.some(e => e.type === winterType)
    : true;
};

const filterByQuickFilters = (s, threatfilter) => {
  // quick filters are winter/nonwinter specific, therefore don't need to filter events by winterType
  const rv = (threatfilter.iceCoating ? s.events.some(e => e.iceCoating) : true) &&
    (threatfilter.minDurationHours !== undefined
      ? s.events.some(
          e =>
            differenceInHours(e.endDate, e.startDate) >=
            threatfilter.minDurationHours
        )
      : true) &&
    (threatfilter.damagingWind ? s.threats.some(t => t.damagingWind) : true) &&
    (threatfilter.activeAlerts
      ? s.advisoryIds && s.advisoryIds.length > 0
      : true) &&
    (threatfilter.floodRisk
      ? s.threats && s.threats.some(t => t.nonwinterWeatherType !== undefined && t.nonwinterWeatherType === 'heavy rain')
      : true) &&
    (threatfilter.isInErrorCone
      ? s.tropicalCycloneInfo && (s.tropicalCycloneInfo.isInErrorCone || (s.tropicalCycloneInfo.distanceFromCone >= 0 && s.tropicalCycloneInfo.distanceFromCone < Number.MAX_SAFE_INTEGER))
      : true) &&
    (threatfilter.thunderStorms
      ?  s.threats && s.threats.some(t => t.nonwinterWeatherType !== undefined && t.nonwinterWeatherType === 'thunderStorm')
      : true)
  // log.debug('filterByQuickFilters', rv);
  return rv
}

const filterByMapSiteFilter = (sid, mapSiteFilterSites) =>
  mapSiteFilterSites.includes(sid);

// apply the "search box" and other filters, based on the "sites" redux state
export function* filterSearchSaga (action) {
  try {
    const sites = yield select((state) => state.sites);
    const threatfilter = yield select ((state) => state.threatfilter);
    const searchbox = yield select ((state) => state.searchbox['threatlist'])
    const siteFilterButton = yield select ((state) => state.togglebutton['sitefilter']);
    const siteFilter = siteFilterButton ? siteFilterButton.label : COMPANY_SITES
    const winterToggle = yield select ((state) => state.togglebutton['winterToggle']);
    const unitToggle = yield select ((state) => state.togglebutton['unitToggle']);
    const winterFilter = winterToggle ? winterToggle.label : undefined;
    const winterType = getWinterType(winterFilter);
    const mySites = yield select ((state) => state.mySites);
    const mapSiteFilterSites = yield select (state => state.mapSiteFilter.selectedSites);
    const timingSelector = yield select (state => state.timingselector['eventtotals']);

    // clear all filteredEvents so these are only set for the Sites that match
    // the filters - otherwise nonwinter site without events could be shown in
    // color (instead of gray) if they have winter events when the user toggles
    // the winterToggle
    Object.values(sites).forEach(s => s.filteredEvents = []);

    // const debugFilters = (n, i, a) => {if(true) console.log(`filter ${n}=`,a.length);}
    const sitesByType = Object.values(sites)
          .filter(s => s)
      // .map((s,i,a) => {debugFilters("1",a);return s;})
          .filter(s => filterByWinterTypeFilter(s, winterFilter))
      // .map((s,i,a) => {debugFilters("2",a);return s;})

    const sitesWithEventsByType = sitesByType
          .filter(s => filterEventsByWinterTypeFilter(s, winterFilter))
      // .map((s,i,a) => {debugFilters("3",a);return s;})

    const visibleSites = sitesWithEventsByType
          .filter(s => filterBySearchBox(s.name||s.zipCode, searchbox))
      // .map((s,i,a) => {debugFilters("4",a);return s;})
          .filter(s => filterByFilterParams({
            site:s, threatfilter, winterType, timingSelector: timingSelector || {},
            unit: unitToggle?.value
          }))
      // .map((s,i,a) => {debugFilters("5",a);return s;})
          .filter(s => filterByMySitesFilter(s, siteFilter, mySites))
      // .map((s,i,a) => {debugFilters("6",a);return s;})
          .filter(s => filterByQuickFilters(s, threatfilter))
      // .map((s,i,a) => {debugFilters("7",a);return s;})
          .map(s => s.id);

    const eventsByType = site => site.events.filter(e => e.type === winterType || winterType === undefined);
    const sitesWithEventsCount = sitesByType
      .filter(s => firstEventPeriodIdx(eventsByType(s)) < 3).length

    // hiddenSites contain all Sites of winter/nonwinter type that dont' have events and have not been
    // caught by other filters
    const hiddenSites = sitesByType
      .filter(s => !filterEventsByWinterTypeFilter(s, winterFilter) || !(s.events||[]).length)
      .filter(s => filterBySearchBox(s.name||s.zipCode, searchbox))
      .filter(s => filterByFilterParams({
        site:s, threatfilter, winterType, timingSelector: timingSelector || {},
        unit: unitToggle?.value,
        siteWithoutEvents: true
      }))
      .filter(s => filterByMySitesFilter(s, siteFilter, mySites))
      .filter(s => filterByQuickFilters(s, threatfilter))
      .map(site => site.id);

    const manualSelectionSites = mapSiteFilterSites.length > 0
      ? [...visibleSites, ...hiddenSites].filter(s => filterByMapSiteFilter(s, mapSiteFilterSites))
      : undefined;

    // log.debug('sites', sites && Object.values(sites).length)
    // log.debug('sitesByType', sitesByType.length)
    // log.debug('visibleSites', visibleSites.length)
    // log.debug('manualSelectionSites', manualSelectionSites)


    yield put({
      type: THREATLIST_SET_VISIBLESITES, 
      visibleSites,
      hiddenSites,
      sitesWithEventsCount,
      manualSelectionSites
    })

    yield put ({
      type: THREATLIST_SET_PAGE,
      pageNo: 0
    })

  } catch (error) {
    log.error('filterSearch saga error', error)
  }
}

// on selecting a Site, whether from map or ThreatList, save scroll position
// on page so it can be restored when returning from expanded threat tile
export function* storePageOffset (action) {
  try {
    const {payload} = action;
    if(payload.selectionType === 'select' && payload.selected){
      const offsetY =  window.pageYOffset || document.documentElement.scrollTop;
      yield put({type: THREATLIST_SET_OFFSET_Y, offsetY});
    } else yield;
    
  } catch (err) {
    log.warn('storePageOffset err', err);
  }
}

export function* forceFilterApply () {
  try {
    yield put({type: THREATLIST_APPLY_FILTER});
  } catch (err) {
    log.warn('forceFilterApply err', err);
  }
}

// create an array of sites to show in threat tiles and maps based on:
// 0. forced filter apply (e.g. when initially loading the data)
// 1. site search box change and
// 2. site filter parameters change
export default function* siteFilterSaga() {
  yield takeEvery(THREATLIST_APPLY_FILTER, filterSearchSaga);
  yield takeLatest("SELECT_SITE", storePageOffset);
  yield takeLatest(
    [
      ACTIONS.REMOVE_SITE_FROM_SELECTED_SITES,
      ACTIONS.ADD_SITE_TO_SELECTED_SITES,
      ACTIONS.RESET_SELECTED_SITES
    ],
    forceFilterApply
  );
}
