import React from 'react'
import { WwaCheckbox } from "../../common/components/WwaInputElements";
import 'common/css/inputElements.css'

const SiteReportCheckbox = ({u, siteId,
    handleReportCheckboxChange, handleReportSubtypeChange,
    reportSubs, isFetching, reportType, hasHazardOption}) => (
  <React.Fragment>
    <WwaCheckbox id={`site-report-user-${u.id}`}
                 name={reportSubs ? reportSubs.id : ''}
                 onChange={(e) => handleReportCheckboxChange(e, u, siteId, reportType)}
                 checked={!!reportSubs}
                 disabled={isFetching}
    />
    {reportType !== "snowtify"
      ?
        <select
          disabled={!reportSubs ? true : false}
          onChange={(e) => handleReportSubtypeChange(e,u,reportSubs)}
          className='wwa__select'
          value={reportSubs
            ? reportSubs.sendIfNecessary
              ? reportSubs.sendIfHazard
                ? "hazards"
                : "events"
              : "always"
            : "events"
          }
        >
          <option
            className='SiteSel__option'
            key={1}
            value="always"
          >Daily</option>
          <option
            className='SiteSel__option'
            key={2}
            value="events"
          >Events Only</option>
          <option
            className='SiteSel__option'
            disabled={!hasHazardOption}
            key={3}
            value="hazards"
            title="Only available for Non-Winter sites."
          >Hazards Only</option>
        </select>
      : null
    }
  </React.Fragment>
)

export default SiteReportCheckbox
