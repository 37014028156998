import log from 'loglevel';
import { put, takeEvery, call, select } from 'redux-saga/effects'
import {canonicalPhone, setIfUndefined} from "common/helpers/helpers";
import { ROLE_DEVELOPER, ROLE_DEVELOPER_RW } from "common/helpers/roles";

import {
  USEREDIT_SUBMIT,
  USEREDIT_SUCCESS,
  USEREDIT_FAILURE,
  USEREDIT_CLOSE
} from '../UsersManageDuck'
import { THIRDPARTYUSEREDIT_CLOSE} from "../UsersManageDuck";
import { PERSONDATA_SUBMIT } from 'common/dataRetrieval/PersonDuck'
import {COMPANY_PERSONDATA_SUBMIT, UPDATE_UNIT_TOGGLE} from 'common/dataRetrieval/CompanyDuck'

import fetchApi from 'utils/fetchApi'

// company users can only be edited (they are created through invitations)
// third party users can be added (with full meta data) or edited (ditto, but their ID must be provided)
export function* postUserEditSaga (action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const { id, username, email, title, 
      mobilePhone, officePhone, extension, newRole, noSmsNotification,
      thirdPartyUser, name, companyName, companyId, reportingOn, copySettingUserId, reportUnit, reportingChangeOnly: patch } = action.payload;
    const authToken = yield select((state) => state.login.authToken);

    let url = `/Persons/${id}`;
    let method = 'PATCH';
    const basePayload = {
      email,
      title: setIfUndefined(patch, action.payload, 'title', title),
      mobilePhone: setIfUndefined(patch, action.payload, 'mobilePhone', canonicalPhone(mobilePhone)),
      officePhone: setIfUndefined(patch, action.payload, 'officePhone', canonicalPhone(officePhone)),
      extension: setIfUndefined(patch, action.payload, 'extension', extension),
      noSmsNotification,
      reportUnit,
    };

    let payload
    if (thirdPartyUser) {
      payload = {
        name: setIfUndefined(patch, action.payload, 'name', name),
        companyName: setIfUndefined(patch, action.payload, 'companyName', companyName),
        copySettingUserId,
        reportingOn,
        ...basePayload
      };
      if (!id) {
        method = 'POST'
        url = `/ThirdPartyUsers` // new
        payload.companyId = companyId
      } else {
        url = `/ThirdPartyUsers/${id}` // edit
        payload.id = id
      }
    } else {
      payload = { username: setIfUndefined(patch, action.payload, 'username', username),  reportingOn, ...basePayload };
      if (newRole) {
        if (newRole.includes(ROLE_DEVELOPER) || newRole.includes(ROLE_DEVELOPER_RW)){
          payload.roles = Array.from(new Set([newRole, 'developer'])); // the first role is the most specific
        } else {
          payload.roles = Array.from(new Set([newRole, 'company-user'])); // unique array
        }
      }
    }

    // call API
    const parsedJson = yield call(fetchApi, url, { payload, authToken, method })
    if (!parsedJson.error){
      yield put({ type: USEREDIT_SUCCESS, userObj: parsedJson })
      if (thirdPartyUser) {
        yield put({type: THIRDPARTYUSEREDIT_CLOSE })
      } else {
        yield put({ type: USEREDIT_CLOSE })
      }

      if (!thirdPartyUser && parsedJson.roles.includes('super-admin'))
        yield put({ type: PERSONDATA_SUBMIT})
      else
        yield put({ type: COMPANY_PERSONDATA_SUBMIT, id: parsedJson.companyId })

      yield put({ type: UPDATE_UNIT_TOGGLE, preferredUnit: parsedJson.reportUnit });
    } else {
      yield put({ type: USEREDIT_FAILURE,
        message: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text,
        payload: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text,
      })
    }
  } catch (error) {
    log.error('postUserEditSaga', error)
  }
}

// listen for actions of type USEREDIT_SUBMIT and use them
export default function* userEditSaga () {
  yield takeEvery(USEREDIT_SUBMIT, postUserEditSaga)
}

