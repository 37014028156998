function getHourDiffBetweenDates(firstDate, secDate){
  return Math.abs(firstDate - secDate) / 36e5;
}

function getDayIndexForHourDiff(diff){

  if(diff < 24){
    return 0;
  } else if(diff < 48){
    return 1;
  } else if(diff < 72){
    return 2;
  } else {
    return 3;
  }
}

export function getEventDays(event){

  const days = [false, false, false, false];

  const startDate =  new Date(event.startDate);
  const endDate =  new Date(event.endDate);
  const currDate = new Date();

  const startDiffH = getHourDiffBetweenDates(startDate, currDate);
  const endDiffH = getHourDiffBetweenDates(endDate, currDate);

  const startIndex = getDayIndexForHourDiff(startDiffH)
  const endIndex = getDayIndexForHourDiff(endDiffH);

  for(var i = startIndex; i <= endIndex; i++){
    days[i] = true;
  }

  return days;
}

// returns an array of 4 elements of boolean value, where 
// [0]="there's an event within the next 24 hours"
// [1]="there's an event between 24 and 48 hours"
// [2]="there's an event between 48 and 72 hours"
// [3]="there's an event after 72 hours"
// This works with events and threats.
export function getAllEventDays(events){

  const days = [false, false, false, false];
  events.forEach(event => {

    const thisDays = getEventDays(event);
    days.forEach((val, index) => {
      days[index] = days[index] || thisDays[index]
    })
  })

  return days;
}

// returns the color of the first period that has an event
export function getColor(days){
  if(days[0]){
    return 'red';
  } else if(days[1]){
    return 'yellow'
  } else if(days[2]){
    return 'green'
  } else if(days[3]){
    return 'magenta'
  } else {
    return 'gray'
  }
}

  // returns the index of the first period that has an event
export function getPeriodDay(days){
  if(days[0]){
    return 0;
  } else if(days[1]){
    return 1;
  } else if(days[2]){
    return 2
  } else {
    return 3
  }
}

// convert array to associative map keyed by ID
export function arr2obj(arr = []) {
  const obj = {};
  arr.forEach(i => { obj[i.id] = i; })
  return obj;
}

export const SHOW_ADDRESS_LINE2 = true;
export const fullSiteAddress = (s, showAddressLine2) =>
  s &&
  (`${s.address1 || ""} ${
    showAddressLine2 && s.address2 ? ", " + s.address2 : ""
  } ${s.city || ""} ${s.state || ""} ${s.zipCode || ""}` ||
    `${Number(s.latLng.lat).toFixed(6)} / ${Number(s.latLng.lng).toFixed(6)}`);

export const fullSiteName = s => {
  if (!s) return '';
  if (s.name) return s.name
  let rv = s.city || ''
  if (s.city && s.state) rv += ', '
  rv += s.state || ''
  return rv
}

export const isAllThreatTypesTrue = typeVal => typeVal &&
  Object.keys(typeVal).length > 0 &&
  Object.values(typeVal).every(t => t === true)
