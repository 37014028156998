import log from 'loglevel';
import { put, takeEvery, call, select } from 'redux-saga/effects'
import {canonicalPhone, setIfUndefined} from "common/helpers/helpers";

import {
  COMPANYEDIT_SUBMIT,
  COMPANYEDIT_SUCCESS,
  COMPANYEDIT_FAILURE,
  COMPANYEDIT_CLOSE,
  COMPANYDELETE_SUBMIT,
  COMPANYDELETE_SUCCESS,
  COMPANYDELETE_FAILURE,
  COMPANYSUBSCRIPTION_SUBMIT,
  COMPANYSUBSCRIPTION_SUCCESS,
  COMPANYSUBSCRIPTION_FAILURE,
  COMPANYROLLUPREPORTSORTCOLUMN_SUBMIT,
  COMPANYROLLUPREPORTSORTCOLUMN_SUCCESS,
  COMPANYROLLUPREPORTSORTCOLUMN_FAILURE,
  COMPANY_SHOW_LOGO_SUBMIT,
  COMPANY_SHOW_LOGO_SUCCESS,
  COMPANY_SHOW_LOGO_FAILURE
} from './CompanyEditDuck'
import {
  COMPANY_APISUBSCRIPTIONS_SET_SUBMIT,
  COMPANY_APISUBSCRIPTIONS_SET_SUCCESS,
  COMPANY_APISUBSCRIPTIONS_SET_FAILURE,
  COMPANY_APISUBSCRIPTIONS_DELETED,
} from 'common/dataRetrieval/CompanyDuck'
import { SUBSCRIPTIONDATA_SUBMIT } from '../../common/dataRetrieval/SubscriptionDuck'
import fetchApi from 'utils/fetchApi';
import { SUBSCRIPTIONSELECTOR_RESET } from "common/components/SubscriptionSelector/SubscriptionSelectorDuck";
import { NOT_API_ENABLED } from 'common/components/SubscriptionSelector/SubscriptionSelector';

export function* fetchCompanyEditSaga (action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const { id, name, address1, state, city, zipCode, primaryPhone, extension, timezone, country, reportUnit } = action.payload;
    const url = `/Companies/${id}`;
    const payload = {
      name,
      address1: setIfUndefined(false, action.payload, 'address1', address1),
      country: setIfUndefined(false, action.payload, 'country', country),
      state: setIfUndefined(false, action.payload, 'state', state),
      city: setIfUndefined(false, action.payload, 'city', city),
      zipCode: setIfUndefined(false, action.payload, 'zipCode', zipCode),
      primaryPhone: setIfUndefined(false, action.payload, 'primaryPhone', canonicalPhone(primaryPhone)),
      extension: setIfUndefined(false, action.payload, 'extension', extension),
      timezone,
      reportUnit };
    const authToken = yield select((state) => state.login.authToken);

    const parsedJson = yield call(fetchApi, url, { payload, authToken, method: 'PATCH' })

    if (!parsedJson.error){
      yield put({ type: COMPANYEDIT_SUCCESS, companyObj: parsedJson, name })
      yield put({ type: COMPANYEDIT_CLOSE })
    } else {
      yield put({
        type: COMPANYEDIT_FAILURE,
        message: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text,
        payload: parsedJson.error.text
      })
    }
  } catch (error) {
    log.error('fetchCompanyEditSaga error', error)
    yield put({
      type: COMPANYEDIT_FAILURE,
      message: 'Network error.'
    })
  }
}

export function* fetchCompanyDeleteSaga({ companyId }) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const url = `/Companies/${companyId}`;
    const authToken = yield select(state => state.login.authToken);

    const parsedJson = yield call(fetchApi, url, {
      authToken,
      method: "DELETE"
    });

    // call will succeed even if ID wrong, but returns number of objects deleted
    if (!parsedJson.error) {
      if (parsedJson.count === 0) {
        yield put({
          type: COMPANYDELETE_FAILURE,
          apiError: "Nothing deleted, is company already deleted?"
        });
      } else {
        yield put ({ type: COMPANYDELETE_SUCCESS, companyId });
      }
    } else {
      yield put({
        type: COMPANYDELETE_FAILURE,
        apiError: parsedJson.error.text._error
          ? parsedJson.error.text._error
          : parsedJson.error.text
      });
    }
  } catch (error) {
    log.error("fetchCompanyDeleteSaga error", error);
    yield put({
      type: COMPANYDELETE_FAILURE,
      apiError: "Network error."
    });
  }
}

export function* postCompanySubscriptionSaga ({companyId, subscriptionId}) {
  try {
    const authToken = yield select((state) => state.login.authToken);
    const subs = yield select((state) => state.subscriptionselector[companyId]);
    const payload = {
      activeSiteLimit: subs.activeSiteUnlimited ? '-1' : subs.activeSiteLimit,
      personLimit: subs.personUnlimited ? '-1' : subs.personLimit,
      active: subs.active,
      callToAction: subs.callToAction,
      addressXfields: subs.addressXfields,
      companyId: subs.companyId,
      types: subs.types,
      reactivateTrial: subs.reactivateTrial ? true : false
    }

    const url = `/Companies/${companyId}/Subscriptions`;
    const method = subscriptionId ? 'PUT' : 'POST'

    const parsedJson = yield call(fetchApi, url, { payload, authToken, method })

    if (!parsedJson.error){
      yield put({ type: COMPANY_APISUBSCRIPTIONS_SET_SUBMIT, companyId, apiPackageId: subs.apiPackageId})
      yield put({ type: COMPANYSUBSCRIPTION_SUCCESS, subscriptionObj: parsedJson, companyId })
      yield put({ type: COMPANYEDIT_CLOSE })
    } else {
      yield put({
        type: COMPANYSUBSCRIPTION_FAILURE,
        message: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text,
        payload: parsedJson.error.text
      })
    }
  } catch (error) {
    log.error('putCompanySubscriptionSaga error', error)
    yield put({
      type: COMPANYSUBSCRIPTION_FAILURE,
      message: 'Network error.'
    })
  }
}

export function* postCompanyRollupReportSortColumn ({companyId, companyRollupReportSortColumn}) {
  try {
    const authToken = yield select((state) => state.login.authToken);
    const url = `/Subscriptions/setCompanyRollupReportSortColumn`;
    const payload = { companyId, companyRollupReportSortColumn };
    const parsedJson = yield call(fetchApi, url, { payload, authToken, method: "POST" })
    if (!parsedJson.error){
      yield put({
        type: COMPANYROLLUPREPORTSORTCOLUMN_SUCCESS,
      })
      yield put({
        type: SUBSCRIPTIONDATA_SUBMIT, 
        companyId
      });
    } else {
      log.error('putCompanySubscriptionSaga json error', parsedJson.error)
      yield put({
        type: COMPANYROLLUPREPORTSORTCOLUMN_FAILURE,
        message: parsedJson.error.text?._error || parsedJson.error.text
      })
    }
  } catch (error) {
    log.error('putCompanySubscriptionSaga error', error)
    yield put({
      type: COMPANYROLLUPREPORTSORTCOLUMN_FAILURE,
      message: 'Network error.'
    })
  }
}

export function* postCompanyShowLogo ({companyId, showLogo}) {
  try {
    const authToken = yield select((state) => state.login.authToken);
    const payload = { showLogo };

    const url = `/Companies/${companyId}/showLogo`;
    const method = 'POST';

    const parsedJson = yield call(fetchApi, url, { payload, authToken, method })

    if (!parsedJson.error){
      yield put({ 
        type: COMPANY_SHOW_LOGO_SUCCESS, 
        companyId, 
        subscriptionId: parsedJson.subscriptionId, 
        showLogo: parsedJson.showLogo
      })
    } else {
      yield put({
        type: COMPANY_SHOW_LOGO_FAILURE,
        apiError: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text
      })
    }
  } catch (error) {
    log.error('postCompanyShowLogo error', error)
    yield put({
      type: COMPANY_SHOW_LOGO_FAILURE,
      message: 'Network error.'
    })
  }
}

export function* connectCompanyToApiSubscription({companyId, apiPackageId}) {
  try {
    const authToken = yield select((state) => state.login.authToken);
    const company = yield select((state) => state.companies[companyId]);
    const apiSubscriptionId = company.apiSubscription ? company.apiSubscription.id : ''

    const url = `/Companies/${companyId}/ApiSubscriptions`

    if (apiSubscriptionId && apiPackageId === NOT_API_ENABLED) {
      yield call(fetchApi, url, { authToken, method: 'DELETE' })
      yield put({
        type: COMPANY_APISUBSCRIPTIONS_DELETED,
        companyId
      })
    } else if(apiPackageId && apiPackageId !== NOT_API_ENABLED) {
      const payload = {
        apiPackageId
      }
      const method = apiSubscriptionId ? 'PUT' : 'POST'

      const parsedJson = yield call(fetchApi, url, { payload, authToken, method })
      if (!parsedJson.error){
        yield put({
          type: COMPANY_APISUBSCRIPTIONS_SET_SUCCESS
        })
      } else {
        yield put({
          type: COMPANY_APISUBSCRIPTIONS_SET_FAILURE,
          message: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text,
        })
      }
    } else {
      yield put({
        type: COMPANY_APISUBSCRIPTIONS_SET_SUCCESS
      })
    }

  } catch (error) {
    log.error('putCompanySubscriptionSaga error', error)
    yield put({
      type: COMPANYSUBSCRIPTION_FAILURE,
      message: 'Network error.'
    })
  }
}

/*
When closing the subscriptions modal, all unsaved changes should be discarded
*/
function* resetSubscriptions(action) {
  try {
    yield put ({type: SUBSCRIPTIONSELECTOR_RESET, companyId: action.companyId})
  } catch (error){
    log.error('resetSubscriptions', error)
  }
}

// listen for actions of type COMPANYEDIT_SUBMIT and use them
export default function* companyEditSaga () {
  yield takeEvery(COMPANYEDIT_SUBMIT, fetchCompanyEditSaga)
  yield takeEvery(COMPANYSUBSCRIPTION_SUBMIT, postCompanySubscriptionSaga)
  yield takeEvery(COMPANYROLLUPREPORTSORTCOLUMN_SUBMIT, postCompanyRollupReportSortColumn)
  yield takeEvery(COMPANY_APISUBSCRIPTIONS_SET_SUBMIT, connectCompanyToApiSubscription)
  yield takeEvery(COMPANYEDIT_CLOSE, resetSubscriptions)
  yield takeEvery(COMPANYDELETE_SUBMIT, fetchCompanyDeleteSaga);
  yield takeEvery(COMPANY_SHOW_LOGO_SUBMIT, postCompanyShowLogo)
}

