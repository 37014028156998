import moment from "moment-timezone";
import log from "loglevel";
import watch from "images/dashboard/threat-watch.svg";
import warning from "images/dashboard/threat-warning.svg";
import advisory from "images/dashboard/threat-advisory.svg";
import forecast from "images/dashboard/threat-forecast.svg";
import outlook from "images/dashboard/threat-outlook.svg";
import statement from "images/dashboard/threat-statement.svg";
import icon_watch from "images/reports/advisory/icon_watch.svg";
import icon_warning from "images/reports/advisory/icon_warning.svg";
import icon_advisory from "images/reports/advisory/icon_advisory.svg";
import icon_forecast from "images/reports/advisory/icon_forecast.svg";
import icon_outlook from "images/reports/advisory/icon_outlook.svg";
import icon_statement from "images/reports/advisory/icon_statement.svg";
import hazard_damagingwind from "images/hazardicons/damagingwind-icon.svg";
import hazard_downedtree from "images/hazardicons/downedbranches-icon.svg";
import hazard_rain from "images/hazardicons/rain-icon.svg";
import hazard_flood from "images/hazardicons/heavyrain-flood-icon.svg";
import hazard_fog from "images/hazardicons/fog-icon.svg";
import hazard_poorvisibility from "images/hazardicons/poorvisibility-icon.svg";
import hazard_hail from "images/hazardicons/hail-icon.svg";
import hazard_hazardousdriving from "images/hazardicons/hazardousdriving-icon.svg";
import hazard_tstorm from "images/hazardicons/thunderstorm-icon.svg";
import hazard_tornado from "images/hazardicons/tornado-icon.svg";
import hazard_wind from "images/hazardicons/wind-icon.svg";
import hazard_convective from "images/hazardicons/convective-icon.svg";
import hazard_blowingsnow from "images/hazardicons/blowingsnow-icon.svg";
import hazard_windchill from "images/hazardicons/windchill-icon.svg";
import hazard_rapidsnow from "images/hazardicons/rapidsnow-icon.svg";
import hazard_heavysleet from "images/hazardicons/heavysleet-icon.svg";
import hazard_hardfreeze from "images/hazardicons/hardfreeze-icon.svg";
import hazard_convective_enhanced from "images/hazardicons/convective-icon-enhanced.svg";
import hazard_convective_general from "images/hazardicons/convective-icon-general.svg";
import hazard_convective_high from "images/hazardicons/convective-icon-high.svg";
import hazard_convective_marginal from "images/hazardicons/convective-icon-marginal.svg";
import hazard_convective_moderate from "images/hazardicons/convective-icon-moderate.svg";
import hazard_convective_slight from "images/hazardicons/convective-icon-slight.svg";
import hurricane from "images/map/map-hurricane.svg";
import nonWinterOff from "images/admin/non-winter-off-icon.png";
import nonWinterOn from "images/admin/non-winter-on-icon.png";
import winterOff from "images/admin/winter-off-icon.png";
import winterOn from "images/admin/winter-on-icon.png";
import snowtifyLogo from "images/admin/snowtify-logo.png";

import {
  ROLE_COMPANY_ADMIN,
  ROLE_DEVELOPER,
  ROLE_DEVELOPER_RW,
  ROLE_SITE_ADMIN,
  ROLE_COMPANY_USER,
  ROLE_SUPER_ADMIN
} from "./roles";
import { canadaProvinceMap, countryMap, usStateMap } from "./locationAbbrev";

const REGEX_MARKER = "!";

// turns any string into mixed case
export const safeColour = c => {
  return mixedCaseWord(c);
};

export const mixedCaseWord = c => {
  return c
    ? `${c[0].toUpperCase()}${c.substring(1).toLowerCase()}`
    : "EMPTYSTRING";
};

export const toMixedCase = s => {
  return s
    ? s
        .split(" ")
        .map(w => mixedCaseWord(w))
        .join(" ")
    : "EMPTYSTRING";
};

export const upperFirstChar = s => s && s.charAt(0).toUpperCase() + s.slice(1);

const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const dateFormatTypes = {
  LARGE_PIN_EVENT_DATE: "LARGE_PIN_EVENT_DATE",
  EVENT_DATE: "EVENT_DATE",
  TIMING: "TIMING",
  TIMING1: "TIMING1",
  ADVISORY_DATE: "ADVISORY_DATE",
  FORECAST_HOUR: "FORECAST_HOUR",
  OBSERVATION_HOUR: "OBSERVATION_HOUR",
  THREAT_GAP_DATE: "THREAT_GAP_DATE",
  CTA_NOTIFICATION: "CTA_NOTIFICATION"
};

export function getFormattedDateWithTZ(
  date,
  site,
  formatType,
  useSiteTimeZone
) {
  if (useSiteTimeZone && !site.timeZoneId) {
    log.debug(`No time zone id for site ${site.name}(id : ${site.id})`);
  }

  const timeZoneId = useSiteTimeZone && site.timeZoneId ? site.timeZoneId : "";
  date = new Date(date);
  const obj = moment.tz(date, timeZoneId);

  let formatStr = "";
  switch (formatType) {
    case dateFormatTypes.ADVISORY_DATE:
    case dateFormatTypes.LARGE_PIN_EVENT_DATE:
      formatStr = "h A, ddd";
      break;

    case dateFormatTypes.EVENT_DATE:
      formatStr = "ddd D MMM, h A";
      break;

    case dateFormatTypes.TIMING:
      formatStr = "ddd, h:mm A";
      break;

    case dateFormatTypes.TIMING1:
      formatStr = "ddd, hA";
      break;

    case dateFormatTypes.OBSERVATION_HOUR:
      formatStr = "h A";
      break;

    case dateFormatTypes.FORECAST_HOUR:
      formatStr = "h A (ddd)";
      break;

    case dateFormatTypes.THREAT_GAP_DATE:
      formatStr = "D MMM, h A";
      break;

    case dateFormatTypes.CTA_NOTIFICATION:
      formatStr = "ddd D MMM, h:mm A Z";
      break;

    default:
      break;
  }

  let result = obj.format(formatStr);

  if (formatType === dateFormatTypes.FORECAST_HOUR)
    result = result.toUpperCase();

  return result;
}

export function getThreatGapDatesFormatted(firstDateStr, secDateStr) {
  const firstArr = firstDateStr.split(",");
  const secArr = secDateStr.split(",");

  if (firstArr[0] === secArr[0]) {
    return `${firstDateStr} - ${secArr[1]}`;
  } else {
    return `${firstDateStr} - ${secDateStr}`;
  }
}

export function getEventTime(dateStr) {
  const dateObj = new Date(dateStr);

  let hours = dateObj.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  return `${hours} ${ampm}, ${days[dateObj.getDay() % 7]}`;
}

function getTimeZone(date) {
  const offset = date.getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    "GMT" +
    (offset < 0 ? "+" : "-") +
    ("00" + Math.floor(o / 60)).slice(-2) +
    ":" +
    ("00" + (o % 60)).slice(-2)
  );
}

export function hourFormatted(dateStr, addDay, addTimeZone) {
  const dateObj = new Date(dateStr);

  let hours = dateObj.getHours();

  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const day = days[dateObj.getDay()];

  return `${hours} ${ampm}${addDay ? ", " + day : ""}${
    addTimeZone ? " " + getTimeZone(dateObj) : ""
  }`;
}

export function eventDateFormatted(dateStr) {
  const dateObj = new Date(dateStr);

  let hours = dateObj.getHours();

  let ampm = hours >= 12 ? "PM" : "AM";
  if (hours === 12) ampm = ampm === "PM" ? "PM noon" : "AM midnight";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const day = dateObj.getDate();
  const month = months[dateObj.getMonth()];

  return `${days[dateObj.getDay() % 7]} ${day} ${month}, ${hours} ${ampm}`;
}

// The number of milliseconds in one day
const ONE_DAY = 1000 * 60 * 60 * 24;
// The number of milliseconds in one hour
const ONE_HOUR = 1000 * 60 * 60;

export function daysBetween(date1, date2) {
  // Convert both dates to milliseconds
  const date1_ms = new Date(date1).getTime();
  const date2_ms = new Date(date2).getTime();

  // Calculate the difference in milliseconds
  const difference_ms = Math.abs(date1_ms - date2_ms);

  // Convert back to days and return
  return Math.round(difference_ms / ONE_DAY);
}

export function hoursBetween(date1, date2) {
  // Convert both dates to milliseconds
  const date1_ms = new Date(date1).getTime();
  const date2_ms = new Date(date2).getTime();

  // Calculate the difference in milliseconds
  const difference_ms = Math.abs(date1_ms - date2_ms);

  // Convert back to days and return
  return Math.round(difference_ms / ONE_HOUR);
}

// codes according to
// https://www.aerisweather.com/support/docs/api/reference/weather-codes/
export const coverageMap = {
  AR: "Areas of",
  BR: "Brief",
  C: "Chance of",
  D: "Definite",
  FQ: "Frequent",
  IN: "Intermittent",
  IS: "Isolated",
  L: "Likely",
  NM: "Numerous",
  O: "Occasional",
  PA: "Patchy",
  PD: "Periods of",
  S: "Slight chance",
  SC: "Scattered",
  VC: "In the vicinity/Nearby",
  WD: "Widespread"
};

export const intensityMap = {
  VL: "Very light",
  L: "Light",
  H: "Heavy",
  VH: "Very heavy",
  "": "Moderate"
};

export const intensityValues = {
  VH: 5,
  H: 4,
  "": 3,
  L: 2,
  VL: 1
};

export const intensityColors = {
  VH: "#F90000",
  H: "#FFCA00",
  "": "grey",
  L: "#0AAC9A",
  VL: "#009CFF"
};

export const barColors = {
  red: "#F90000",
  yellow: "#FFCA00",
  green: "#0AAC9A",
  blue: "#009CFF",
  black: "#000000",
  gray: "#7F8FA4"
};

export const barColorsTransparent = {
  red: "#F9000099",
  yellow: "#FFCA0099",
  green: "#0AAC9A99",
  blue: "#009CFF99",
  black: "#00000099",
  gray: "#7F8FA499"
};

export const weatherMap = {
  A: "Hail",
  BD: "Blowing dust",
  BN: "Blowing sand",
  BR: "Mist",
  BS: "Blowing snow",
  BY: "Blowing spray",
  F: "Fog",
  FR: "Frost",
  H: "Haze",
  IC: "Ice crystals",
  IF: "Ice fog",
  IP: "Ice pellets / Sleet",
  K: "Smoke",
  L: "Drizzle",
  R: "Rain",
  RW: "Rain showers",
  RS: "Rain/snow mix",
  SI: "Snow/sleet mix",
  WM: "Wintry mix (snow, sleet, rain)",
  S: "Snow",
  SW: "Snow showers",
  T: "Thunderstorms",
  UP: "Unknown precipitation",
  VA: "Volcanic ash",
  WP: "Waterspouts",
  ZF: "Freezing fog",
  ZL: "Freezing drizzle",
  ZR: "Freezing rain",
  ZY: "Freezing spray"
};

export const constructedWeather = code => {
  const weather = code.split(":");
  return `${coverageMap[weather[0]]} ${intensityMap[weather[1]]} ${
    weatherMap[weather[2]]
  }`;
};

export const popRound = pop => {
  const floor = Math.floor(pop / 5) * 5;
  const diff = pop - floor;
  return diff >= 2.5 ? floor + 5 : floor;
};

export const getCoverageCode = code => {
  return code.split(":")[0];
};

export const getIntensityCode = code => {
  return code.split(":")[1];
};

export const maxIntensity = (a, b) => {
  const iv1 = intensityValues[a];
  const iv2 = intensityValues[b];
  return iv1 > iv2 ? a : b;
};

// extracts weather primary code and turns it into human readable form
export const code2intensity = code => {
  return intensityMap[getIntensityCode(code)];
};

export const getWeatherCode = code => {
  return code.split(":")[2];
};

export const code2weather = code => {
  return weatherMap[getWeatherCode(code)];
};

export const date2Color = (date, ongoing=false) => {
  const hoursTillDate = hoursBetween(new Date(), date);
  if (hoursTillDate <= 24) return ongoing ? "Blue" : "Red";
  if (hoursTillDate <= 48) return "Yellow";
  if (hoursTillDate <= 72) return "Green";
  return "Blue";
};

export const advisoryPrio = ["W", "A", "Y", "S", "NOW", "O"];
export const advisoryCompare = (a, b) =>
  advisoryPrio.indexOf(codeFromAdvisory(a.type)) -
  advisoryPrio.indexOf(codeFromAdvisory(b.type));

export const codeFromAdvisory = code => {
  return code.includes(".") ? code.split(".")[1] : code;
};
export const code2advisory = code => {
  switch (codeFromAdvisory(code)) {
    case "NOW":
      return "Forecast";
    case "LEW":
      return "Law Enforcement Warning";
    case "W":
      return "Warning";
    case "A":
      return "Watch";
    case "Y":
      return "Advisory";
    case "AQA":
    case "S":
      return "Statement";
    case "O":
      return "Outlook";
    default:
      return "Statement"; // unknown code, but show as "Statement"
  }
};

const advIconMap = {
  Warning: warning,
  Watch: watch,
  Advisory: advisory,
  Statement: statement,
  Outlook: outlook,
  Forecast: forecast
};

export const getAdvisoryIcon = type => advIconMap[type.type];

const hazardIconMap = {
  'damagingWind': hazard_damagingwind,
  'downedBranches': hazard_downedtree,
  'fog': hazard_fog,
  'hail': hazard_hail,
  'sighail': hazard_hail,
  'hazardousDriving': hazard_hazardousdriving,
  'flood': hazard_flood,
  'heavyRain': hazard_flood,
  'rain': hazard_rain,
  'thunderStorm': hazard_tstorm,
  'tornado': hazard_tornado,
  'sigtornado': hazard_tornado,
  'wind': hazard_wind,
  'sigwind': hazard_wind,
  'cat': hazard_convective,
  'poorVisibility': hazard_poorvisibility,
  'blowingSnow': hazard_blowingsnow,
  'windChill': hazard_windchill,
  'rapidSnowAccumulation': hazard_rapidsnow,
  'heavySleet': hazard_heavysleet,
  'hardFreezePotentialIntensity': hazard_hardfreeze,
  'hurricane': hurricane,
};

const categoryIconMap = {
  'SLIGHT': hazard_convective_slight,
  'MODERATE': hazard_convective_moderate,
  'MARGINAL': hazard_convective_marginal,
  'HIGH': hazard_convective_high,
  'GENERAL': hazard_convective_general,
  'ENHANCED': hazard_convective_enhanced,
};

export const getHazardIcon = ({ type, name }) =>
  type === "cat" ? categoryIconMap[name] : hazardIconMap[type];

const reportAdvIconMap = {
  warning: icon_warning,
  watch: icon_watch,
  advisory: icon_advisory,
  statement: icon_statement,
  outlook: icon_outlook,
  forecast: icon_forecast
};

export const getReportAdvisoryIcon = type => {
  return reportAdvIconMap[type];
};

export const hazardMap = {
  h: "High",
  m: "Med",
  l: "Low"
};

export const hazardColors = {
  // 'h': '#F90000',
  h: "rgba(249,0,0,0.2)", // rgba allows for background opacity without affecting text
  // 'm': '#FFCA00',
  m: "rgba(255,202,0,0.2)",
  // 'l': '#0AAC9A',
  l: "rgba(10,172,154,0.2)",
  "": "rgba(127,143,164,0.23)" //#7F8FA4
};

export const displayRole = r => {
  switch (r) {
    case ROLE_SUPER_ADMIN:
      return "Super Admin";
    case ROLE_SITE_ADMIN:
      return "Site Admin";
    case ROLE_COMPANY_ADMIN:
      return "Company Admin";
    case ROLE_COMPANY_USER:
      return "Company User";
    case ROLE_DEVELOPER:
      return "API Developer";
    case ROLE_DEVELOPER_RW:
      return "API Developer with create/read/update/delete";
    default:
      return "-";
  }
};

export const machineRole = r => {
  switch (r) {
    case "Super Admin":
      return ROLE_SUPER_ADMIN;
    case "Site Admin":
      return ROLE_SITE_ADMIN;
    case "Company Admin":
      return ROLE_COMPANY_ADMIN;
    case "Company User":
      return ROLE_COMPANY_USER;
    case "API Developer":
      return ROLE_DEVELOPER;
    case "API Developer with create/read/update/delete":
      return ROLE_DEVELOPER_RW;
    default:
      return undefined;
  }
};

// third party users don't have roles, company users do
export const isThirdPartyUser = p => p && p.email && !p.roles;
export const isDevUser = p =>
  p &&
  p.roles &&
  (p.roles.includes(ROLE_DEVELOPER) || p.roles.includes(ROLE_DEVELOPER_RW));
export const isCompanyUser = p => p && p.roles && p.roles.includes(ROLE_COMPANY_USER);
export const isCompanyAdmin = p => p && p.roles && p.roles.includes(ROLE_COMPANY_ADMIN);
export const isSiteAdmin = p => p && p.roles && p.roles.includes(ROLE_SITE_ADMIN);
export const isSuperAdmin = p => p && p.roles && p.roles.includes(ROLE_SUPER_ADMIN);

// determines arrays of UTF characters by user role;
// these characters are only useful for the FontAwesome font, which should be set
// in CSS class, e.g.
// .MultiSearchBoxSelector__select--outer select {
//   font-family: 'FontAwesome', 'sans-serif';
// }
const userIcons = p => {
  if (isCompanyAdmin(p)) {
    return [0xf023];
  } else if (isSiteAdmin(p)) {
    return [0xf041];
  } else if (isCompanyUser(p)) {
    return [0xf007];
  } else if (isThirdPartyUser(p)) {
    return [0xf2be];
  } else {
    return [];
  }
};

// returns for a user "p" UTF characters according to their role;
// primarily for MultiSearchBoxSelector
export const getUserIcons = p => (
  p && userIcons(p)
      .filter(i => i)
      .reduce((acc,i) => {acc += String.fromCharCode(i); return acc;}, '')
);

export const getSiteIcons = s =>
  s && s.types ? s.types.map(t => (t === "winter" ? "❄ " : "💧")) : "";

export const safeLimit = limit => {
  if (!limit) return "0";
  return limit === -1 ? "∞" : limit;
};

export const safeLimits = (count, limit) => {
  let lmt = `/ ${safeLimit(limit)}`;
  return `${count !== undefined ? count : "0"} ${lmt}`;
};

export const getLastPartOfUrl = match => {
  const pieces = match && match.path && match.path.split("/");
  return pieces ? pieces[pieces.length - 1] : "";
};

/*
Trace for Snow:
0.01" → 0.05" = (T) Trace Snow
or
If snow in forecast and < 0.01" = (T) Trace Snow
These codes:
“RS” : “Rain/snow Mix”,
“SI” : “Snow/sleet Mix”,
“WM” : “Wintry Mix”,
“S” : “Snow”,
“SW” : “Snow Showers”,
“BS” : “Blowing Snow”,

Coating for Ice:
0.01" → 0.05" (C) Coating Ice
 */

// from https://stackoverflow.com/a/54837128
// will round 0.05 to 0.1 for places==1
const roundToDecimalPlaces = (number, places) => {
  let rounder = "1";

  for (let i = 0; i < places; i++) {
    rounder += "0";
  }

  return Math.round(number * rounder) / rounder;
};

// show forecast snowIN values
export const genSnowStr = (code, snow) => {
  const numval = Number(snow) || 0;
  return roundToDecimalPlaces(numval, 2);
};

export const fix = (v, i=1) => isNaN(v) ? "0."+"".padStart(i,"0") : Number(v).toFixed(i);

const ICE_IGNORE_LIMIT = 0.005;
const ICE_TRACE_LIMIT = 0.05;
const ICE_COATING_LIMIT = 0.1;

export const genIceStr = iceIn => {
  if (!iceIn || +iceIn < ICE_IGNORE_LIMIT) return fix(0);
  if (+iceIn < ICE_TRACE_LIMIT) return "(T)";
  if (+iceIn < ICE_COATING_LIMIT) return "(C)";
  if (+iceIn <= 0.1) return fix(iceIn);
  return `${parseInt(+iceIn * 10, 10) / 10}`;
};

export const formatLatitude = lat => {
  const compass = lat > 0 ? "N" : "S";
  return `${Math.abs(Number(lat)).toFixed(5)} °${compass}`;
};

export const formatLongitude = lng => {
  const compass = lng < 0 ? "W" : "E";
  return `${Math.abs(Number(lng)).toFixed(5)} °${compass}`;
};

// rounds in steps of 5: 71->70, 75->75, 78->80
export const roundTo5 = v => Math.round(v / 5) * 5;

// turns any phone number of 10 digits (whether containing dashes or not)
// into the canonical US form "111-222-3333"
export const canonicalPhone = phone =>
  !phone
    ? phone
    : phone.replace(/-/g, "").replace(/(\d{3})(\d{3})(\d{4})/g, "$1-$2-$3");

// returns a shortened string that contains the ellipsis in the middle
// i.e. the beginning and end of string are returned: "one very very long string" becomes "one very...string"
export const elliptic = (string, len = 10) =>
  string && string.length > len
    ? `${string.substring(0, len / 2)}…${string.substring(
        string.length - len / 2,
        string.length
      )}`
    : string;

// string ellipsis that occurs only on word breaks with at least "len"
// characters plus any characters until the next word boundary
// i.e. "Mostly cloudy with a light chance of wintry rain" becomes "Mostly cloudy with …"
export const wordEllipsis = (s, len = s.len) => {
  const re = new RegExp("^(.{" + len + "}[^s]*).*");
  const newStr = s.replace(re, "$1");
  return s.length > len + 3 ? `${newStr} …` : s;
};


const setUnconditionally = (payload, field, value) =>
  value !== undefined ? value : (Array.isArray(payload[field]) ? [] : "");
const patchField = (payload, field, value) =>
  value !== undefined ? value : payload[field];

export const setIfUndefined = (patch, ...rest) =>{
  return patch
    ? patchField(...rest)
    : setUnconditionally(...rest);
}

export const isIE11 = window.navigator.userAgent.match(/Trident/i);

export const scrollTo = (elname, alignToTop) =>
  document.getElementById(elname) && document.getElementById(elname).scrollIntoView(alignToTop || { behavior: "smooth" });

// joins the first elements of arr with comma ", " and last with ampersand "&": "6AM EST, 12PM EST & 5PM EST"
export const specialJoin = arr => {
  if (arr === undefined || !arr || !arr.length) return "";
  if (arr.length === 1) return arr[0];
  return arr.slice(0, arr.length - 1).join(", ") + " & " + arr[arr.length - 1];
};

export const hasAnyRole = (userRoles, rolesToCheck) =>
  userRoles.filter(ur => rolesToCheck.some(r => r === ur)).length;

export const reportTypes = [
  "company", 
  "companyWithSites",
  "customReport",
  "site"
]

export const friendlyReportType = reportType => {
  switch (reportType) {
    case "site":
      return "Site Report (PDF)";
    case "company":
      return "Company Rollup (Excel)";
    case "companyWithSites":
      return "My Sites Rollup (Excel)";
    case "customReport":
      return "Custom Rollup (Excel)";
    case "snowtify":
      return "Snowtify";
    default:
      return reportType;
  }
};

export const reportTypeToolTip = reportType => {
  switch (reportType) {
    case "site":
      return "A PDF forecast for only one site: Features large amounts of detail for winter threats, with the most focus on the next 24 hrs.";
    case "company":
      return "All sites, all threats, sort by lead time, site name, or precipitation type within an Excel Document.";
    case "companyWithSites":
      return "Only your personally assigned sites threats, sort by lead time, site name, or precipitation type within an Excel Document.";
    case "customReport":
      return "Similar to the Company Rollup, but with a custom threshold applied for certain precip types, forecasted totals, and threats. Sort by lead time, site name, or precipitation type.";
    default:
      return reportType;
  }
};

export const PHONE_DUMMY = "xxx-xxx-xxxx";
export const N_A = "N/A";

export const positiveOrZero = v => (!isNaN(v) && v >= 0 ? v : 0);

// check if value of searchBox matches against string "s"
// if "s" AND searchBox value are undefined, match is true, else false
export const filterBySearchBoxLiteral = (s, searchBox) => {
  return s
    ? s
        .toLowerCase()
        .includes(
          searchBox && searchBox.value
            ? searchBox.value.trim().toLowerCase()
            : ""
        )
    : searchBox && searchBox.value
      ? false
      : true;
};

export const filterBySearchBoxRegex = (s, searchBox) => {
  /* regex code, which can be activated when the first character in searchBox is REGEX_MARKER;
   * will treat search string as RegEx, which means "(" and ")" etc must be escaped
   */
  if (
    s &&
    searchBox &&
    searchBox.value &&
    searchBox.value.charAt(0) === REGEX_MARKER
  ) {
    try {
      return !!s.match(
        new RegExp(searchBox ? searchBox.value.substring(1) : "", "i")
      );
    } catch (e) {
      return s
        .toLowerCase()
        .includes(
          searchBox && searchBox.value ? searchBox.value.toLowerCase() : ""
        );
    }
  } else {
    return filterBySearchBoxLiteral(s, searchBox);
  }
};

export const filterBySearchBox = (s, searchBox) =>
  filterBySearchBoxLiteral(s, searchBox);
//export const filterBySearchBox = (s, searchBox) => filterBySearchBoxRegex(s, searchBox);

// curried function that takes a searchTerm and returns a function that tries to find that term in its param string
export const matchSearchTerm = searchTerm => s =>
  s ? s.toLowerCase()
       .includes(searchTerm !== undefined ? searchTerm.trim().toLowerCase() : "")
    : searchTerm
      ? false
      : true;

export const siteNameSortFun = (a, b, dir) => {
  if (a === b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  if (a.toUpperCase() < b.toUpperCase()) return -dir;
  return dir;
};

export const sortWithDirectionFun = (a, b, dir) => {
  if (a === b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  const a1 = a === "Trace" ? 0.001 : +a.replace(/'/g,"");
  const b1 = b === "Trace" ? 0.001 : +b.replace(/'/g,"");
  if(Number.isNaN(+a1) || Number.isNaN(+b1))
    return dir > 0 
      ? a.toUpperCase().localeCompare(b.toUpperCase()) 
      : b.toUpperCase().localeCompare(a.toUpperCase());
  else 
    return a1 < b1 ? -dir : dir;
};

// safely accesses deeply nested field in object
export const idx = (path, obj) => path.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, obj)
// embed deeply nested field in string, but only if field found, otherwise return empty string
export const idxAnd = (path, obj) => s => {
  const res = idx(path, obj);
  return res 
    ? s.replace('%s', res)
    : ''; 
}

export const idxAndArray = (path, obj) => s => {
  const res = idx(path, obj);
  const valueString = Array.isArray(res) ? res.join(", ") : res;
  return valueString 
    ? s.replace('%s', valueString)
    : ''; 
}

// Considering a certain target page count (to avoid having too many pages),
// compute the size of page that is necessary to be near that target.
export const getOptimalPageSize = (count, targetPageCount = 20) => {
  if(count === undefined) return 10;
  if(count/targetPageCount < 15) return 10;
  if(count/targetPageCount < 30) return 25;
  if(count/targetPageCount < 80) return 50;
  if(count/targetPageCount < 250) return 100;
  return 250;
};

// compares sites by name, with empty names being sorted at the front
// only if both names are undefined, compare by city, zipCode (in that order)
export const sortSiteByName = (a, b, sortDirection) => (
  safeCompare(a.name, b.name, sortDirection) ||
  safeCompare(a.city, b.city, sortDirection) ||
  safeCompare(a.zipCode, b.zipCode, sortDirection)
);

const safeCompare = (a, b, sortDirection) => {
  if (a === b) return 0;
  if (!a) return sortDirection;
  if (!b) return -sortDirection;
  return sortDirection > 0 
    ? a.toUpperCase().localeCompare(b.toUpperCase()) 
    : b.toUpperCase().localeCompare(a.toUpperCase());
}

const keySortFun = (obj, k, dir = 1) => (a, b) =>
  a && b && obj[a] && obj[b] && dir > 0
    ? obj[a][k].localeCompare(obj[b][k])
    : obj[b][k].localeCompare(obj[a][k]);

export const sortMapByKey = (obj, key = 'name', dir) => {
  const sortFun = keySortFun(obj, key, dir);
  const sortedKeys = Object.keys(obj).sort(sortFun)
  const newObj = {};
  sortedKeys.forEach(k => newObj[k] = obj[k]);
  return newObj;
}

export function getWinterStateFromToggleButton(togglebutton){
  if(!togglebutton || !togglebutton.winterToggle || !togglebutton.winterToggle.label){
      return 'winter';
  }
  
  const label = togglebutton.winterToggle.label.toLowerCase();
  if(label.indexOf('non-winter') >= 0){
      return 'nonwinter';
  } else {
      return 'winter'
  }        
}

export const winterTypeIcons = {
  winter: { true: winterOn, false: winterOff },
  nonwinter: { true: nonWinterOn, false: nonWinterOff },
  snowtify: { true: snowtifyLogo, false: snowtifyLogo },
};

export function getUnitFromToggleButton(togglebutton){
  if(!togglebutton?.unitToggle?.value){
      return 'Imperial';
  }
  
  return togglebutton.unitToggle.value;
}

export const cappedInchValue = ( v, capValue ) => v > capValue ? `${capValue}''+` : `${v}''`;
export const cappedCmValue = ( v, capValue ) => v > capValue ? `${Number(capValue).toFixed(1)}cm+` : `${v}cm`;
export const cappedMmValue = ( v, capValue ) => v > capValue ? `${capValue}mm+` : `${v}mm`;

export const isDemoSystem = () => window.location.hostname.startsWith('demo.') || process.env.REACT_APP_FORCE_DEMO_MODE

export const timezones = {
  "America/New_York": "EST/EDT", // Eastern Standard Time / Eastern Daylight Time
  "America/Chicago": "CST/CDT", // Central Standard Time / Central Daylight Time
  "America/Denver": "MST/MDT", // Mountain Standard Time / Mountain Daylight Time
  "America/Los_Angeles": "PST/PDT", // Pacific Standard Time / Pacific Daylight Time
  "America/Phoenix": "Arizona (MST)", // Mountain Standard Time, no DST observation
  "America/Anchorage": "AKST/AKDT", // Alaska Standard Time / Alaska Daylight Time
  "Pacific/Honolulu": "HST" // Hawaii Standard Time, no DST observation
};

export const DEFAULT_TIMEZONE = "America/New_York";

export const DEFAULT_COUNTRY = "US";

export const canadaTimezones = {
  "America/St_Johns": "NST/NDT", // Newfoundland Standard Time / Newfoundland Daylight Time
  "America/Halifax": "AST/ADT", // Atlantic Standard Time / Atlantic Daylight Time
  "America/Toronto": "EST/EDT", // Eastern Standard Time / Eastern Daylight Time
  "America/Winnipeg": "CST/CDT", // Central Standard Time / Central Daylight Time
  "America/Edmonton": "MST/MDT", // Mountain Standard Time / Mountain Daylight Time
  "America/Vancouver": "PST/PDT", // Pacific Standard Time / Pacific Daylight Time
};

export const formatAddressForDisplay = (address) => {
  return `${address.city || ''}, ${formatState(address.state, address.country) || ''}, ${address.zipCode || ''}, ${countryMap[address.country || DEFAULT_COUNTRY] || ''}`;

}

export const formatState = (state, country) => {
  const stateMap = country === "US" ? usStateMap : canadaProvinceMap;
  return stateMap[state] || state || '';
}
export const REPORT_UNITS = {
  "imperial": "US (Imperial)",
  "metric": "Metric"
}
