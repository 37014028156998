import log from 'loglevel';
import { put, take, cancel, takeLatest, takeEvery, call, select } from 'redux-saga/effects';


import { UPDATE_WINTER_TOGGLE, UPDATE_UNIT_TOGGLE, COMPANYDATA_SUBMIT } from 'common/dataRetrieval/CompanyDuck';
import {
  LOGIN_SUBMIT,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  USERDATA_SUCCESS,
  AUTH_EXPIRED_COUNTDOWN
} from './LoginDuck';
import fetchApi from 'utils/fetchApi';

export function* fetchLoginSaga(action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const { username, password } = action.payload;
    const url = '/persons/login';
    const payload = { email: username?.trim(), password: password?.trim(), ttl: 1209600 /* 1209600 seconds; default=14 days */ };

    // call API
    const parsedJson = yield call(fetchApi, url, { payload });

    const authToken = parsedJson.id;

    if (!parsedJson.error) {
      const parsedUserJson = yield call(
        fetchApi,
        `/persons/${parsedJson.userId}`,
        { method: 'GET', authToken }
      );
  
      yield put({ type: USERDATA_SUCCESS, userObj: parsedUserJson });
      yield put({ type: LOGIN_SUCCESS, userObj: parsedJson });
      yield put({ type: UPDATE_WINTER_TOGGLE, companyId: parsedJson.companyId });
      if(parsedUserJson.roles.includes('super-admin')) {
        yield put({ type: COMPANYDATA_SUBMIT });
      } else {
        yield put({ type: COMPANYDATA_SUBMIT, fullFetch: true });
      }
      // if parsedUserJson has preferredUnit for imperial or metric, use that instead of company's country for unitToggle
      if(parsedUserJson.reportUnit) {
        yield put({ type: UPDATE_UNIT_TOGGLE, preferredUnit: parsedJson.reportUnit });
      } else {
        yield put({ type: UPDATE_UNIT_TOGGLE, companyId: parsedJson.companyId });
      }
    } else {
      // 'payload' is the redux-form-submit-saga object that is given wholesale to
      // react-final-form's error state
      yield put({
        type: LOGIN_FAILURE,
        message: parsedJson.error.text,
        payload: parsedJson.error.text._error ? {...parsedJson.error.text} : {_error: parsedJson.error.text},
      });
    }
  } catch (error) {
    log.error('fetchLoginSaga error', error); // something happened during the network call
    yield put({
      type: LOGIN_FAILURE,
      message: 'Failed to login',
      payload: {_error: 'Network error.'}
    });
  }
}

const delay = millis =>
  new Promise(resolve => {
    setTimeout(() => resolve(true), millis);
  });

// when login token expires, start a countdown to show in LayoutComponent to
// tell user that they will be automatically redirected to the Login page
export function* countDownToLogout(action) {
  try {
    const authExpiredCounter = (yield select((state) => state.login.authExpiredCounter)) ?? 20;
    if(authExpiredCounter > 0) {
      yield call(delay, 1000);
      yield put({type: AUTH_EXPIRED_COUNTDOWN, authExpiredCounter: authExpiredCounter - 1});
    }
  } catch (error) {
  }
}

export function* logDataFailure(action) {
  if(action.message === 'Invalid access token.' || action.message?._error === 'Authorization required.' || action.message === 'Authorization required.'){
    const authExpiredCounter = yield select((state) => state.login.authExpiredCounter);
    if(authExpiredCounter === undefined)
      yield put({type: AUTH_EXPIRED_COUNTDOWN, authExpiredCounter: 20});
  } else {
    yield;
  }
}

// listen for actions of type LOGIN_SUBMIT and use them
export default function* loginSaga() {
  yield takeEvery(LOGIN_SUBMIT, fetchLoginSaga);
  yield takeEvery('DATA_FAILURE', logDataFailure);
  yield takeEvery('APIPACKAGES_FAILURE', logDataFailure);
}

export function* authExpiredSaga() {
  while(true){
    const authTake = yield takeLatest(AUTH_EXPIRED_COUNTDOWN, countDownToLogout);
    yield take('LOGOUT_SUBMIT');
    yield cancel(authTake);
  }
}
