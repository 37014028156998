import React from 'react';
import { WwaCheckbox } from "../../common/components/WwaInputElements";
import { winterTypeIcons } from "common/helpers/helpers";
import 'common/css/inputElements.css'

const getWinterIcon = (winterType, on) =>
  winterType ? (
    <img
      src={winterTypeIcons[winterType][true]}
      alt={winterType}
      style={{ width: "20px", height: "20px", opacity: "0.8", marginRight: "0.3em" }}
    />
  ) : null;

const BulkSiteReportCheckbox = ({id, checked, hasHazardOption, selectedValue, disabled, handleReportCheckboxChange, handleReportSubtypeChange, winterType, showIcon = false}) => (
  <div>
    {showIcon && getWinterIcon(winterType, true)}
    <WwaCheckbox id={id}
                 name={'site-report'}
                 onChange={(e) => handleReportCheckboxChange(e)}
                 checked={checked}
                 disabled={disabled}
    />
    { id !== "snowtify"
      ? 
        <select
          id={id}
          disabled={!checked ? true : false}
          onChange={(e) => handleReportSubtypeChange(e)}
          className='wwa__select'
          value={selectedValue}
        >
          <option
            className='SiteSel__option'
            key={1}
            value="always"
          >Daily
          </option>
          <option
            className='SiteSel__option'
            key={2}
            value="events"
          >Events Only
          </option>
          <option
            className='SiteSel__option'
            disabled={!hasHazardOption}
            key={3}
            value="hazards"
            title="Only available for Non-Winter sites."
          >Hazards Only
          </option>
        </select>
      :
      <span> Snowtify </span>
    }
  </div>
)

export default BulkSiteReportCheckbox
