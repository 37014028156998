import isEqual from 'lodash/isEqual'
import { USERUNASSIGN_SUCCESS } from 'Admin/SiteUsersManage/UserUnassign/UserUnassignDuck';

// posting ADD
export const SUBSCRIBE_REPORT_SUBMIT = 'SUBSCRIBE_REPORT_SUBMIT'
export const SUBSCRIBE_REPORT_SUCCESS = 'SUBSCRIBE_REPORT_SUCCESS'
export const SUBSCRIBE_REPORT_FAILURE = 'SUBSCRIBE_REPORT_FAILURE'

// posting DELETE
export const UNSUBSCRIBE_REPORT_SUBMIT = 'UNSUBSCRIBE_REPORT_SUBMIT'
export const UNSUBSCRIBE_REPORT_SUCCESS = 'UNSUBSCRIBE_REPORT_SUCCESS'
export const UNSUBSCRIBE_REPORT_FAILURE = 'UNSUBSCRIBE_REPORT_FAILURE'

// posting UPDATE
export const SUBSCRIBE_REPORT_UPDATE_SUBMIT = 'SUBSCRIBE_REPORT_UPDATE_SUBMIT'
export const SUBSCRIBE_REPORT_UPDATE_SUCCESS = 'SUBSCRIBE_REPORT_UPDATE_SUCCESS'
export const SUBSCRIBE_REPORT_UPDATE_FAILURE = 'SUBSCRIBE_REPORT_UPDATE_FAILURE'

// getting data
export const USER_REPORTSUBS_SUBMIT = 'USER_REPORTSUBS_SUBMIT'
export const USER_REPORTSUBS_SUCCESS = 'USER_REPORTSUBS_SUCCESS'
export const USER_REPORTSUBS_FAILURE = 'USER_REPORTSUBS_FAILURE'

export const USER_REPORTSUBS_BY_COMPANY_SUBMIT = 'USER_REPORTSUBS_BY_COMPANY_SUBMIT'
export const USER_REPORTSUBS_BY_COMPANY_SUCCESS = 'USER_REPORTSUBS_BY_COMPANY_SUCCESS'
export const USER_REPORTSUBS_BY_COMPANY_FAILURE = 'USER_REPORTSUBS_BY_COMPANY_FAILURE'


export const COMPANY_REPORT = 'COMPANY_REPORT'
export const SITE_REPORT = 'SITE_REPORT'

const subscribeReportForUser = (userId, companyId, siteId, siteReportType, type, userType) => ({
  type: SUBSCRIBE_REPORT_SUBMIT,
  userId, // person.id or ThirdPartyUser.id
  companyId,
  siteId,
  siteReportType,
  sendIfNecessary: true, // default value
  sendIfHazard: false, // default value
  reportType : type,
  cbAction:  {
      type: USER_REPORTSUBS_SUBMIT,
      userId: userId,
      userType: userType
    }
})

const unsubscribeReportForUser = (userId, companyId, siteId, reportType, userType) => ({
  type: UNSUBSCRIBE_REPORT_SUBMIT,
  userId, // person.id or ThirdPartyUser.id
  companyId,
  siteId,
  reportType,
  cbAction:  {
      type: USER_REPORTSUBS_SUBMIT,
      userId: userId,
      userType: userType
    }
})

const updateReportSubscriptionOfUser = (userId, companyId, subscriptionId, sendIfNecessary, sendIfHazard, userType) => ({
  type: SUBSCRIBE_REPORT_UPDATE_SUBMIT,
  userId,
  companyId,
  subscriptionId,
  sendIfNecessary,
  sendIfHazard,
  cbAction:  {
      type: USER_REPORTSUBS_SUBMIT,
      userId: userId,
      userType: userType
    }
})

export const subscribeCompanyReport = (userId, companyId, companyReportType, userType) => subscribeReportForUser(userId, companyId, null, undefined, companyReportType, userType)
export const unsubscribeCompanyReport = (userId, companyId, companyReportType, userType) => unsubscribeReportForUser(userId, companyId, null, companyReportType, userType)
export const updateReport = (userId, companyId, subscriptionId, sendIfNecessary, sendIfHazard, userType) => updateReportSubscriptionOfUser(userId, companyId, subscriptionId, sendIfNecessary, sendIfHazard, userType)
export const subscribeSiteReport = (userId, companyId, siteId, siteReportType, userType) => subscribeReportForUser(userId, companyId, siteId, siteReportType, undefined, userType)
export const unsubscribeSiteReport = (userId, companyId, siteId, siteReportType, userType) => unsubscribeReportForUser(userId, companyId, siteId, siteReportType, userType)

export const getAllReportsForUser = (userId, userType) => ({
  type: USER_REPORTSUBS_SUBMIT,
  userId,
  userType // 'person' or 'third-party-user'
})

const subscriptionArr2state = (state, subscriptions) => {
  const newState = {...state, isFetching: false};
  subscriptions.forEach(s => {
    newState[s.personId] = newState[s.personId] || [];
    newState[s.thirdPartyUserId] = newState[s.thirdPartyUserId] || [];
    if (s.personId) newState[s.personId].push(s);
    if (s.thirdPartyUserId) newState[s.thirdPartyUserId].push(s);
  })
  return newState
}

const initialState = {
  isFetching: false,
  isFetchingGet: false,
  apiError: undefined,
  error: false
}

const uniqueSubsArr = arr => {
  const newarr = arr.reduce((acc, srs) => (
    acc.find(a => a.id === srs.id) ? acc : acc.concat(srs)
  ), []);
  if(!isEqual(arr,newarr)) console.log(`arr:${arr}
  newarr:${newarr}`);

  return newarr;
};

const userReportSubscriptions = (state = initialState, action) => {
  switch(action.type){

    case USER_REPORTSUBS_SUBMIT:
      return {
        ...state,
        isFetchingGet: true,
        error: false
      }

    case USER_REPORTSUBS_SUCCESS:
      return {
        ...state,
        [action.userId]: uniqueSubsArr(action.subsArr),
        isFetchingGet: false
      }

    case USER_REPORTSUBS_FAILURE:
      return {
        ...state,
        isFetchingGet: false,
        error: true
      }

    case USERUNASSIGN_SUCCESS:
      // after unassigning user from site, also replicate removal of report
      // subscriptions in backend, but only if user has a subscription
      if(state[action.userId]) 
        return {
          ...state,
          [action.userId]: state[action.userId].filter(subs =>
            action.siteId !== subs.siteId
          )
        }
      else return state;

    case USER_REPORTSUBS_BY_COMPANY_SUBMIT:
      return {
        ...state,
        isFetching: true,
        apiError: undefined
      }
    case USER_REPORTSUBS_BY_COMPANY_SUCCESS:
      return subscriptionArr2state(state, action.subscriptions)

    case USER_REPORTSUBS_BY_COMPANY_FAILURE:
      return {
        ...state,
        isFetching: false,
        apiError: action.apiError
      }

    case SUBSCRIBE_REPORT_SUBMIT:
      return {
        ...state,
        isFetching: true
      }
    case SUBSCRIBE_REPORT_SUCCESS:
    case SUBSCRIBE_REPORT_FAILURE:
      return {
        ...state,
        isFetching: false
      }

    case SUBSCRIBE_REPORT_UPDATE_SUBMIT:
      return {
        ...state,
        isFetching: true
      }
    case SUBSCRIBE_REPORT_UPDATE_SUCCESS:
    case SUBSCRIBE_REPORT_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false
      }


    case UNSUBSCRIBE_REPORT_SUBMIT:
      return {
        ...state,
        isFetching: true
      }
    case UNSUBSCRIBE_REPORT_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    case UNSUBSCRIBE_REPORT_SUCCESS:
      return {
        ...state,
        isFetching: false,
      }



    default :
      return state;
  }
}

export default userReportSubscriptions
